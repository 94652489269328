<template>
<div>
    <div class="row page-title align-items-center">
        <div class="col-md-3 col-xl-6">
            <h4 class="mb-1 mt-0">{{ pageTitle }} <span v-if="currentCustomer.name">: {{ currentCustomer.name }}</span></h4>
        </div>
        <div class="col-md-9 col-xl-6 text-md-right">
            <div class="mt-4 mt-md-0">
                <button type="button" @click="goback()" class="btn btn-dark mr-2 mb-3 mb-sm-0">
                    <i class="mr-1"></i> Go Back
                </button>
            </div>
        </div>
    </div>
    <div class="card" v-if="currentCustomer">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12 col-xl-7">
                    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                        <form class="form-horizontal" @submit.prevent="handleSubmit(saveCustomer)" autocomplete="nope">

                            <h4 class="header-title">Name</h4>
                            <b-row class="mb-3">
                                <b-col sm="3">
                                    <label class="mt-2 float-right" for="inputEmail3">Name</label>
                                </b-col>
                                <b-col sm="6">
                                    <ValidationProvider v-slot="{ errors }" name="locationname" :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 100 }" :custom-messages="customMessages">
                                        <b-form-input v-model="currentCustomer.name" name="locationname" autocomplete="nope"></b-form-input>
                                        <span class="text-danger text-sm">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row class="mb-3">
                                <b-col sm="3">
                                    <label class="mt-2 float-right" for="inputEmail3">&nbsp;</label>
                                </b-col>
                                <b-col sm="6">
                                    <button type="button" class="btn btn-primary" @click="saveCustomer">Submit</button>
                                </b-col>
                            </b-row>

                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import addressSuggest from '@components/addressSuggest'
import Layout from '@layouts/main'
import PageHeader from '@components/page-header'

import {
    treeData
} from '@state/api/tree';

import {
    customMessages
} from '@utils/validationmessage'

import {
    companyData
} from '@state/api/company';

export default {
    page: {
        title: 'Locations',
        meta: [{
            name: 'My Locations',
            content: ""
        }],
    },
    components: {
        addressSuggest,
        Layout,
        PageHeader
    },
    data() {
        return {
            customMessages,
            address: "",
            stateList: [],
            countryList: [],
            pageTitle: 'Add New Customer',
            currentCustomer: {}
        }
    },
    computed: {
        customerId() {
            return this.$route.params.id;
        }
    },
    async created() {
    },
    methods: {
        goback() {
            this.$router.back();
        },
        async saveCustomer() {
            const success = await this.$refs.form.validate();
            if (!success) {
                return;
            }

            let apiResult = await companyData.createCompany(this.currentCustomer);
            if (!apiResult.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }

            this.$vs.notify({
                title: "Success",
                text: "Record Saved",
                color: "success",
                position: "top-center"
            });
            this.$router.back();
        }
    }
}
</script>

<style lang="scss">
#profile-tabs {
    .vs-tabs--content {
        padding: 0;
    }
}
</style>
